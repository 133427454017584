class Loader {
  static get instance() {
    if (!Loader._instance)
      Loader._instance = new Loader();

    return Loader._instance;
  }
  constructor() {
    this.element = document.querySelector('.loading-backdrop-js');
  }

  show() {
    addClass(this.element, 'now-loading');
  }

  hide() {
    removeClass(this.element, 'now-loading');
  }
}

function genPages() {
  let Pages = [
    [DatePage, '.date-page-js'],
    [RoomPage, '.room-page-js'],
    [DetailsPage, '.details-page-js'],
    [ConfirmPage, '.confirm-page-js'],
    [PaymentPage, '.payment-page-js'],
    [PaymentCallbackPage, '.payment-callback-page-js']
  ];

  return Pages
    .map(([Page, querySelector], index) => {
      return Page.byQuerySelector(querySelector, { index })
    })
    .map((page, index, pages) => {
      let next = pages[index + 1];

      if (next !== undefined)
        page.next = next;

      return page;
    });
}

window.addEventListener('load', () => {
  let { success = null, debug = false, page = 0 } = queryParams();

  let tld = window.location.hostname.split('.').splice(-1)[0];

  if (success === null) {
    if (!(tld === 'localhost' && debug))
      localStorage.clear();

    let pages = genPages();
    Page.first.show();

    if (tld === 'localhost' && debug)
      pages[page].show();
  } else {

    let pages = genPages();
    pages[5].show({ success: success === 'true' });
  }
});
